import React from "react"
import classNames from "classnames"

import RafaelBonavitaPhoto from "images/musicians/RafaelBonavita.jpg"
import FrancoisGuerrierPhoto from "images/musicians/FrancoisGuerrier.jpg"
import HubertMittermayerNesterovskiyPhoto from "images/musicians/HubertMittermayerNesterovskiy.jpg"
import JanKrigovskyPhoto from "images/musicians/JanKrigovsky.jpg"
import OriolAymatPhoto from "images/musicians/OriolAymat.jpg"
import RobertOberaignerPhoto from "images/musicians/RobertOberaigner.jpg"
import MariyaNesterovskaPhoto from "images/musicians/MariyaNesterovska.jpg"
import NenadLecicPhoto from "images/musicians/NenadLecic.jpg"
import MarceloNisinmanPhoto from "images/musicians/MarceloNisinman.jpg"
import DraganTrajkovskiPhoto from "images/musicians/DraganTrajkovski.jpg"

import MusicianTile from "./tile/MusicianTile"
import { SECTION_NAMES } from "common/sectionNames"

import styles from "./MusiciansSection.module.scss"
import commonStyles from "../../common/commonStyles.module.scss"

const musicians = [
  {
    name: "Oriol Aymat Fusté",
    photo: OriolAymatPhoto,
    info: `Oriol Aymat Fusté, Cello (ESP) – Geboren in Katalonien. Studium bei Leonid Gorokhov an der Yehudi 
    Menuhin School (UK) und bei Young-Chang Cho in Essen. Preisträger renommierter Wettbewerbe wie Germans Claret, 
    European Youth Cello Competition. Rege Konzerttätigkeit auf barockem und modernem Cello u.a. mit Vespres d’Arnadí, 
    L’ arte del mondo, Camerata XXI und Camerata 432 sowie der katalanischen Band Blaumut.`,
  },
  {
    name: "Rafael Bonavita",
    photo: RafaelBonavitaPhoto,
    info: `Rafael Bonavita (URY) – Geboren in Montevideo. Studium der klassischen Gitarre bei Alvaro Pierri und Antiker 
    Lauten und Gitarren bei Hopkinson Smith. Weltweite Konzerttätigkeit u.a. mit Jordi Savall, 
    René Jacobs und Marc Minkovski. Zahlreiche Aufnahmen für Radio und Fernsehen in Europa und Asien. 
    CD-Einspielungen für das Label Enchiriadis mit Musik von Bach, Bartolotti, de Murcia und 
    Sanz sowie Eigenkompositionen.`,
  },
  {
    name: "François Guerrier",
    photo: FrancoisGuerrierPhoto,
    info: `François Guerrier, Cembalo/Orgel (F) – Geboren in Caen. Studium am Conservatoire Superieur de Paris bei Pierre Hantaï, 
    Elisabeth Joyé, Christophe Rousset und Kenneth Weiss. Preisträger des Internationalen Bach-Wettbewerbes, 
    Leipzig 2006. Konzertreisen als Solist und Kammermusiker in Europa, Amerika und Asien mit Partnern wie 
    Stéphanie-Marie Degand, Ricercar Consort und Il Gardellino. CD-Einspielungen mit Werken von Couperin, 
    Mazzocchi und Bach.
    `,
  },
  {
    name: "Nenad Lecic",
    photo: NenadLecicPhoto,
    info: `Nenad Lecic (SRB) – Geboren in Čačak. Studium in Belgrad und Köln bei Igor Lasko, Arbo Valdma und 
    Pierre-Laurent Aimard. Debüt mit Orchester mit 11 Jahren. Young-European-Talent der RAI. 
    Preisträger zahlreicher internationaler Wettbewerbe. Konzerte in ganz Europa im Rahmen 
    renommierter Konzertreihen wie Klavierfestival Ruhr, Pianorama (WDR), Atelier (Kölner Philharmonie). 
    Dozent der Musikhochschule Köln.`,
  },
  {
    name: "Mariya Nesterovska",
    photo: MariyaNesterovskaPhoto,
    info: `Mariya Nesterovska, Violine und Leitung (UA) – Geboren in Kiew. Studium in Kiew, Hamburg, Köln und Gent u.a. bei Ludmilla Ovcharenko und 
    Mikhail Bezverkhny. Als Solistin und Kammermusikerin bereits in jungen Jahren vielfache Preisträgerin 
    internationaler Wettbewerbe u.a. in Paris, Belgrad, Covilha, Atri, Ragusa / New York. Konzerte in 
    ganz Europa. CDs mit Werken von Skoryk, Schnittke, Franck, Telemann und Kantscheli.`,
  },
  {
    name: "Hubert Mittermayer Nesterovskiy",
    photo: HubertMittermayerNesterovskiyPhoto,
    info: `Hubert Mittermayer Nesterovskiy, Fagott und Leitung (A) – Geboren in Innsbruck. Studium bei G. Klütsch (Weimar / Köln). 
    Solofagott in Orchestern in San Sebastian, 
    Rotterdam, Köln, Turin, Mahler Chamber Orchestra u. Ensemble Modern mit Dirigenten wie Sir C. Davis, 
    L. Maazel, P. Järvi, Chr. Eschenbach und P. Boulez. Solist mit Orquesta Filarmónica de Buenos Aires 
    und Capella Istropolitana. Festivals Luzern, Ludwigsburg und Schleswig-Holstein.`,
    useCondensedFont: true,
  },
  {
    name: "Robert Oberaigner",
    photo: RobertOberaignerPhoto,
    info: `Robert Oberaigner, Klarinette (A) – Geboren in Hall in Tirol, Studium u.a. bei Horst Hajek, Johann Hindler, Sabine Meyer 
    und Eric Hoeprich in Wien, Lübeck und Den Haag. Früher Soloklarinettist des Gürzenich Orchesters Köln,
    seither der Sächsischen Staatskapelle Dresden. Solo- und Kammermusiker mit PartnerInnen wie Sol Gabetta und Nils Nönkemeyer, Schwerpunkt auf 
    zeitgenössischer Musik und 
    historischen Instrumenten. Dozent am Tiroler Landeskonservatorium. 
    `,
  },
]

export default ({ onRef }) => (
  <div
    id={SECTION_NAMES.ARTISTS}
    ref={onRef}
    className={classNames(commonStyles.sectionWrapper, styles.sectionWrapper)}
  >
    <h2 className={classNames(commonStyles.title)}>
      Auf der B&uuml;hne starke Pers&ouml;nlichkeiten
    </h2>
    <div className={styles.musicians}>
      {musicians.map((musician, i) => (
        <MusicianTile
          key={`musician-${i}`}
          {...musician}
          style={{ zIndex: musicians.length - i }}
        />
      ))}
    </div>
  </div>
)
