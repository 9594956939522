import React from "react"
import classNames from "classnames"

import styles from "./ContactsSection.module.scss"
import commonStyles from "../../common/commonStyles.module.scss"

export default () => (
  <div className={styles.sectionWrapper}>
    <h2 className={classNames(commonStyles.title, styles.title)}>
      Wir machen die Sommermusik
    </h2>

    <div className={styles.content}>
      <div className={styles.section}>
        Veranstalter sind wir als OBERT&Ouml;NE Kammermusikverein. Unser
        Anliegen sind Konzerte exzellenter k&uuml;nstlerischer Qualit&auml;t mit
        dem Anspruch, Menschen durch unkonventionelle Programmgestaltung und
        direkte, pers&ouml;nliche Art des Musikmachens anzusprechen. Bekannt
        sind wir bereits von unserem anderen großen Projekt:
      </div>

      <div className={styles.sectionTitle}>Die OBERTÖNE Kammermusiktage</div>
      <div className={styles.section}>
        Jeden September kommen K&uuml;nstlerInnen aus aller Welt nach Tirol um
        eine Woche lang in Stams zu musizieren und an vier Konzertabenden das
        Publikum zu begeistern.
        <br /> Das ist Chamber music wonderland!
      </div>

      <div className={styles.section}>
        Mehr Info:&ensp;{" "}
        <a href="http://www.obertone.at/" className={styles.obertoneLink}>
          www.obertone.at
        </a>
      </div>

      <div className={styles.sectionTitle}>Unseren kostenlosen Newsletter</div>
      <div className={styles.section}>
        bequem hier abbonieren &ndash; <br />
        so sind Sie immer als Erste informiert:
      </div>
      <br />
      <div className={styles.section}>
        <iframe
          width="100%"
          height="280px"
          border="0"
          border-width="0px"
          src="https://emt-sghzhqx1d.sendserver.email/hp/QaaZRl-44mUC88rjXHD7dQ/signup"
        ></iframe>
      </div>

      <div className={styles.sectionTitle}>Für den Inhalt verantwortlich</div>
      <div className={styles.section}>
        <p>
          OBERT&Ouml;NE Kammermusikverein
          <br />
          Postfach 04, 6029 Innsbruck{" "}
        </p>
        <p>
          Obmann:&ensp;Toni Innauer
          </p>
        <p>
          K&uuml;nstlerische Leitung:&ensp;<nobr>
            Mariya Nesterovska
          </nobr> und <nobr>Hubert Mittermayer Nesterovskiy</nobr>
        </p>
        <p>
          Presse und &Ouml;ffentlichkeitsarbeit:&ensp;
          <a href="mailto:presse@obertone.at" className={styles.obertoneLink}>
            Dr.&thinsp;Juliane Mayer
          </a>
        </p>
        <p>
          Konzertmanagement und Organisation:&ensp;
          <a href="mailto:info@obertone.at" className={styles.obertoneLink}>
            Katharina Jeske
          </a>
        </p>
        +43 512 312 306
        <br />
        <a href="mailto:info@obertone.at" className={styles.obertoneLink}>
          info@obertone.at
        </a>
        <br />
      </div>

      <div className={styles.footer}>
        <br />
        <p>
          &Auml;nderungen in Programm und Besetzung vorbehalten.
          <br />
        </p>
        <p>
          Bildnachweis: Alle Fotos von <nobr>Mariya Nesterovska</nobr> (außer
          &bdquo;Hintergrundbild Unsere&nbsp;Partner&ldquo; von{" "}
          <nobr>Heinz Zak</nobr>, &bdquo;Robert Oberaigner&ldquo; von Andrej
          Kasik und &bdquo;Oriol Aymat Fust&eacute;&ldquo;)
          <br />
          Design: <nobr>Hubert Mittermayer Nesterovskiy</nobr>
          <br />
          Programmierung: <nobr>Andrey Filenko</nobr>
        </p>
        ©2025 OBERT&Ouml;NE Kammermusikverein
      </div>
    </div>
  </div>
)
